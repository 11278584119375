<template>
  <div class="content d-flex justify-content-center align-items-center">
    <form class="login-form" @submit.prevent="newPassword">
      <div class="card mb-0">
        <div class="card-body">
          <div class="text-center mb-3">
            <img
              src="@/assets/img/logo.png"
              class="img-fluid mx-auto d-block"
              alt="Logo"
              width="60%"
            />
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': $v.password.$error }"
              placeholder="Senha"
              v-model.trim="$v.password.$model"
            />
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted" />
            </div>
            <span v-if="!$v.password.minLength" class="invalid-feedback">
              A senha deve ter pelo menos
              {{ $v.password.$params.minLength.min }} caracteres
            </span>
          </div>
          <div class="form-group form-group-feedback form-group-feedback-left">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': $v.confirmPassword.$error }"
              placeholder="Confirme a Senha"
              v-model.trim="$v.confirmPassword.$model"
            />
            <div class="form-control-feedback">
              <i class="icon-lock2 text-muted" />
            </div>
            <span
              v-if="!$v.confirmPassword.sameAsPassword"
              class="invalid-feedback"
            >
              As senhas devem ser idênticas
            </span>
          </div>
          <div class="form-group">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              title="Criar Nova Senha"
              :disabled="$v.$invalid || disableButton"
            >
              <span v-if="disableButton">
                <i class="icon-spinner2 spinner" />
              </span>
              <span v-else>Criar Nova Senha</span>
            </button>
          </div>
          <div class="text-center" title="Login">
            <router-link to="/">Login</router-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'NewPassword',

  data() {
    return {
      key: this.$route.params.key,
      password: '',
      confirmPassword: '',
      disableButton: false,
    }
  },

  methods: {
    async newPassword() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('newPassword', {
            key: this.key,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })

          this.resetForm()

          if (response && response.message) {
            this.$root.$children[0].$refs.notification.success(response.message)
            this.$router.push(PATHS.AUTH)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$children[0].$refs.notification.warning(error.message)
          } else {
            this.$root.$children[0].$refs.notification.danger(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.key = ''
      this.password = ''
      this.confirmPassword = ''
      this.$v.$reset()
    },
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      sameAsPassword: sameAs('password'),
    },
  },
}
</script>
